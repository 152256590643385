























































// 这个模块是为了兼容，第三方的引入单独提炼出来的
import { Component, Vue, Ref } from 'vue-property-decorator';

import YwhzTitleCard from '@/components/YwhzLayoutCard/YwhzTitleCard/index.vue';

import WarehouseSelect from './components/WarehouseSelect.vue';
import CountrySelect from './components/CountrySelect.vue';
import GoodsSelect from './components/GoodsSelect.vue';
import TransportType from './components/TransportType.vue';
import YwhzRenderTable from './components/RenderTable.vue';
import YwhzFreightForm from './components/FreightForm.vue';

import { otherModule } from '@/store/modules/other';

import { GetPriceSheet, GetCalculateTrial } from '@/api/api';

@Component({
    name: 'ChargeStandard',
    components: {
        YwhzFreightForm,
        YwhzRenderTable,
        YwhzTitleCard,
        TransportType,
        WarehouseSelect,
        CountrySelect,
        GoodsSelect
    }
})
export default class ChargeStandard extends Vue {
    @Ref('form') private readonly formRef: any;

    public isSuchData: any = true;

    // 俩个输入框加载完成，才进行表格的加载
    public loaded: any = {
        countryLoaded: false,
        goodsLoaded: false
    };

    public data: any = {};

    // 列子数据
    public suchData: any = {};

    // 表格数据
    public tableData = [];

    public tableDataBudget: any = [];

    public freightData: any = []; // 运费数据

    public formData: any = {
        CountryId: '',
        GoodsTypeId: '',
        TransportTypeId: '1', // 运输方式 也可用于当作下标
        Length: '',
        Width: '',
        Height: '',
        Weight: '',
        gross: 0
    }

    private created() {
        const { sty } = this.$route.query;
        if (sty && sty === 'false') {
            this.isSuchData = false;
        }
    }

    public async init() {
        const { Data } = await GetPriceSheet({
            CountryId: this.formData.CountryId,
            GoodsTypeId: this.formData.GoodsTypeId
        });
        this.data = Data;
    }

    // 选中
    public transportChange(data) {
        // 运输方式
        this.formData.TransportTypeId = data.TransportTypeId;
        this.tableData = [];
        data.ListShopPriceSheetDetail.forEach(item => {
            if (item.ListShopPriceSheetStandard.length) {
                item.ListShopPriceSheetStandard[0].WareHouseNames = item.WareHouseNames.split(',').join('|');
                item.ListShopPriceSheetStandard[0].rowspan = item.ListShopPriceSheetStandard.length;
                item.ListShopPriceSheetStandard[0].CarrierNames = `(${item.CarrierNames})`;
            }
            this.tableData = [...item.ListShopPriceSheetStandard, ...this.tableData];
        });
        this.budgetInit();
        this.suchData = data;
    }

    public async budgetInit() {
        const is = this.isCntinue();
        if (is) {
            return;
        }
        const { Data } = await GetCalculateTrial(this.formatForm());
        this.freightData = Data;
        this.tableDataBudget = [];
        Data.forEach(item => {
            if (item.ListFreightFeeDetail.length) {
                // 挑选包稅
                item.ListFreightFeeDetail = item.ListFreightFeeDetail.filter((value) => {
                    return value.IsTax === this.formData.gross;
                });
                item.ListFreightFeeDetail[0].WareHouseNames = item.WareHouseNames;
                item.ListFreightFeeDetail[0].CarrierNames = item.CarrierNames;
                item.ListFreightFeeDetail[0].rowspan = item.ListFreightFeeDetail.length;
            }
            this.tableDataBudget = [...item.ListFreightFeeDetail, ...this.tableDataBudget];
        });
    }

    // 体积查询
    public async submit() {
        try {
            const params = JSON.parse(JSON.stringify(this.formData));
            if (params.GoodsTypeId === '') {
                params.GoodsTypeId = '-1';
            }
            await this.formRef.validate();
            await otherModule.setFreight(params);
            await this.$router.push({
                name: 'StandardDetails'
            });
        } catch (err) {
            console.warn(err);
        }
    }

    // 如果长宽高体积有一个有值 false 不执行
    private isCntinue() {
        let is = true;
        const arr = ['Length', 'Width', 'Height', 'Weight'];
        for (let i = 0; i < arr.length; i++) {
            const key = arr[i];
            if (this.formData[key] !== '') {
                is = false;
                break;
            }
        }
        return is;
    }

    // 表单如果为空的话，默认传值到后端是0 (只处理长/宽/高/体积)
    private formatForm() {
        const data = JSON.parse(JSON.stringify(this.formData));
        const arr = ['Length', 'Width', 'Height', 'Weight'];
        arr.forEach(key => {
            if (data[key] === '') {
                data[key] = 0;
            }
        });
        return data;
    }

    public changeLoaded() {
        if (this.loaded.countryLoaded && this.loaded.goodsLoaded) {
            this.init();
        }
    }
}
