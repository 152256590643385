








import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

// 运输方式
@Component({
    name: 'TransportType',
    components: {}
})
export default class TransportType extends Vue {
    @Prop({ type: [Object, Array], default: {} }) public options!:any;

    public activeIndex: any = '';

    // public options: Array<any> = [
    //     { Text: '海快运费', Value: 1 },
    //     { Text: '空运运费', Value: 2 },
    //     { Text: '海运运费', Value: 3 }
    // ];

    public change(item, index) {
        this.activeIndex = item.TransportTypeId;
        this.$emit('change', this.options[index]);
    }

    @Watch('options', { immediate: true, deep: true })
    public changeFirst(val) {
        if (val.length) {
            const item = val[0];
            this.activeIndex = item.TransportTypeId;
            this.$emit('change', item);
        }
    }

    public get listIcon() {
        return (id) => {
            let icon = '';
            // eslint-disable-next-line eqeqeq
            if (id == '1') {
                icon = 'iconfont icon-jiyunleixing-kongyun';
            }

            // eslint-disable-next-line eqeqeq
            if (id == '2') {
                icon = 'iconfont icon-jiyunleixing-haikuai';
            }

            // eslint-disable-next-line eqeqeq
            if (id == '4') {
                icon = 'iconfont icon-jiyunleixing-haiyun';
            }
            return icon;
        };
    }

}
